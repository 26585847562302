import React from "react"

const FAQ = () => (

      <section class="section" id="faq">
          <div class="container">
              <div class="row justify-content-center text-center">
                  <div class="col-lg-12">
                      <i class="ti-comments title-icon text-muted"></i>
                      <h2>Frequently asked <span class="font-weight-bold">questions</span></h2>
                  </div>
              </div>
              <div class="row mt-5">
                  <div class="col-lg-6">
                      <div class="pl-4 pr-4">
                          <div class="mt-4">
                              <div class="">
                                  <h5 class="mb-0 faq-que">
                                  Q. What’s included in your Unlimited Plan?</h5>
                              </div>
                              <div class="faq-ans">
                                  <p class="text-muted">No hidden surprises
                                  here. Our Unlimited Plan covers everything.
                                  Create as many tasks as you like without
                                  worrying about additional hourly or per-task
                                  rates.</p>
                              </div>
                          </div>
                          <div class="mt-5">
                              <div class="">
                                  <h5 class="mb-0 faq-que">
                                  Q. I create a task. What happens next? </h5>
                              </div>
                              <div class="faq-ans">
                                  <p class="text-muted">
                                  Our team takes your task and breaks it down
                                  into a series of sub-tasks, so you can see
                                  how we’ll get you from where you are to where
                                  you want to be. We even let you know how many
                                  we can get through per week. We then execute
                                  those sub-tasks, one by one.</p>
                              </div>
                          </div>
                          <div class="mt-5">
                              <div class="">
                                  <h5 class="mb-0 faq-que">
                                  Q. What if I have a larger, more general task,
                                  like “improve site performance”?</h5>
                              </div>
                              <div class="faq-ans">
                                  <p class="text-muted">
                                  We’ve got you covered. If you join our Grow
                                  plan and create that task, our team of
                                  engineers figures out the smaller, individual
                                  components required to ladder up to your
                                  overall goal and transparently shares those
                                  with you. You’ll get a timeline that shows
                                  when to expect completion of each of the
                                  components, and then a final date when you
                                  should notice your needle moving on the
                                  original ask.</p>
                              </div>
                          </div>
                          <div class="mt-5">
                              <div class="">
                                  <h5 class="mb-0 faq-que">
                                  Q. What if I’m unhappy with the service?</h5>
                              </div>
                              <div class="faq-ans">
                                  <p class="text-muted">
                                  We’re committed to delivering unparalleled
                                  work, in quality and service. However,
                                  sometimes a relationship doesn’t work out.
                                  We get that. Simply tell us and we’ll issue
                                  a (prorated) refund for the remainder of
                                  your month.</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="pl-4 pr-4">
                          <div class="mt-4">
                              <div class="">
                                  <h5 class="mb-0 faq-que">
                                  Q. Do you work with other agencies and
                                  freelancers?</h5>
                              </div>
                              <div class="faq-ans">
                                  <p class="text-muted">
                                  Absolutely. We have formal agreements with
                                  some partner organizations already. We’re
                                  happy to meet your freelancers and make sure
                                  that we can all work together toward your
                                  success.</p>
                              </div>
                          </div>
                          <div class="mt-5">
                              <div class="">
                                  <h5 class="mb-0 faq-que">
                                  Q. Can I choose my Managed Commerce team
                                  members?</h5>
                              </div>
                              <div class="faq-ans">
                                  <p class="text-muted">
                                  We endeavor to assign the same staff to you
                                  so they intimately know your businesses and
                                  site. However, we can’t guarantee that the
                                  person you love will always be available.</p>
                              </div>
                          </div>
                          <div class="mt-5">
                              <div class="">
                                  <h5 class="mb-0 faq-que">
                                  Q. Are high priority issues handled
                                  faster?</h5>
                              </div>
                              <div class="faq-ans">
                                  <p class="text-muted">
                                  Any issue impacting your website’s ability to
                                  checkout customers is treated as a severe
                                  event. We prioritize these events before all
                                  other work.</p>
                              </div>
                          </div>
                          <div class="mt-5">
                              <div class="">
                                  <h5 class="mb-0 faq-que">
                                  Q. What are your working hours?</h5>
                              </div>
                              <div class="faq-ans">
                                  <p class="text-muted">
                                  It depends on the plan you purchase. For
                                  customers on Grow Plans, we are available
                                  Monday through Friday, from 6am EST to 6pm
                                  EST. For Scale and Enterprise Plan customers,
                                  we are available 24/7.</p>
                              </div>
                          </div>

                          <div class="mt-5">
                              <div class="">
                                  <h5 class="mb-0 faq-que">
                                  Q. I have a big deadline coming up. Can I get
                                  more tasks done faster?</h5>
                              </div>
                              <div class="faq-ans">
                                  <p class="text-muted">
                                  Definitely. After adding some tasks to your
                                  account, you’ll have the option to pay for a
                                  burst of resources to your plan. We call these 
                                  “sprints” because we’ll work as fast as
                                  possible to get the job done properly. We’ll
                                  swarm our resources around your project and
                                  get things finished by your deadline.</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
)

export default FAQ
