import React from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby"

const Pricing = () => (
  <section class="section bg-light mt-5" id="pricing">
          <div class="container">
              <div class="row justify-content-center text-center">
                  <div class="col-lg-12">
                      <i class="ti-money title-icon text-muted"></i>
                      <h2><span class="font-weight-bold">Unlimited</span> monthly plans</h2>
                      <p class="text-muted mt-3 title-subtitle mx-auto"></p>
                  </div>
              </div>
              <div class="row mt-5">
                  <div class="col-lg-4">
                      <div class="bg-white price-box text-center mt-3">
                          <h4>Grow</h4>
                          <div class="plan-price">
                              <h1 class="mb-0 font-weight-bold">$399</h1>
                              <p class="mb-0">Per Month</p>
                          </div>
                          <div class="plan-features text-muted mt-5 mb-5">
                              <p class="mb-3 pr-3 pl-3"><b class="text-custom">12/5</b> Email and chat support with our standard <b class="text-custom">3 day</b> task turnaround.</p>
                              <p><i class="ti-check mr-2"></i> Bug fixes</p>
                              <p><i class="ti-check mr-2"></i> App setup</p>
                              <p><i class="ti-check mr-2"></i> Design tweaks</p>
                              <p class="mb-5"><i class="ti-check mr-2"></i> Theme edits</p>

                              <p><i class="ti-calendar mr-2 mb-2"></i> Monthly checkins<br/><br/></p>
                          </div>
                          <div>
                              <a href="#" class="btn btn-cta">Try Now</a>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-4">
                      <div class="bg-white price-box active text-center mt-3">
                          <h4>Scale</h4>
                          <div class="plan-price">
                              <h1 class="mb-0 font-weight-bold">$999</h1>
                              <p class="mb-0">Per Month</p>
                          </div>
                          <div class="plan-features text-muted mt-5 mb-5">
                              <p class="mb-3 pr-3 pl-3"><b class="text-custom">24/7</b> email and chat support with our faster <b class="text-custom">2 day</b> task turnaround.</p>
                              <p><i class="ti-check mr-2"></i> Everything in <i>Grow</i></p>
                              <p><i class="ti-check mr-2"></i> Advanced coding</p>
                              <p><i class="ti-check mr-2"></i> Larger projects</p>
                              <p class="mb-5"><i class="ti-check mr-2"></i> Custom functionality</p>

                              <p><i class="ti-calendar mr-2 mb-2"></i>Monthly performance, analytics, and conversion checkins</p>

                          </div>
                          <div>
                              <a href="#" class="btn btn-cta">Try Now</a>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-4">
                      <div class="bg-white price-box text-center mt-3">
                          <h4>Enterprise</h4>
                          <div class="plan-price">
                              <h1 class="mb-0 font-weight-bold">Call</h1>
                              <p class=" mb-0">Per Month</p>
                          </div>
                          <div class="plan-features text-muted mt-5 mb-5">
                              <p class="mb-3 pr-3 pl-3"><b class="text-custom">24/7</b> email and chat support with our fastest <b class="text-custom">1 day</b> task turnaround.</p>
                              <p><i class="ti-check mr-2"></i> Everything in <i>Grow and Scale</i></p>
                              <p><i class="ti-check mr-2"></i> Dedicated Project Manager</p>
                              <p><i class="ti-check mr-2"></i> Advanced integrations</p>
                              <p class="mb-5"><i class="ti-check mr-2"></i> Complex storefronts</p>

                              <p><i class="ti-calendar mr-2 mb-2"></i>Weekly performance, analytics, and conversion checkins</p>

                          </div>
                          <div>
                            <Link to="/enterprise" className="btn btn-outline-secondary" style={{fontSize: "18px", color:"#333", textTransform: "none"}}>Find out more</Link>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

)

export default Pricing
